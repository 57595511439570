import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const ProtectedPDFDownload = () => {
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, isLoading } = useAuth0();
  const navigate = useNavigate();

  const downloadPDF = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        'https://us-central1-raahtech-website-server.cloudfunctions.net/app/whitepapers/whitepaper1/secure-download',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to download PDF');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'THE JOURNEY TO NEXT GEN IAM - RAAH Tech.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Redirect to /home after download completes
      navigate('/home');
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  useEffect(() => {
    if (isLoading) return; // Wait until authentication state is fully loaded
    if (!isAuthenticated) {
      loginWithRedirect(); // Trigger login if not authenticated
    } else {
      downloadPDF(); // Start download once authenticated
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]); // Ensure dependencies are correct

  return <div>Redirecting to download...</div>;
};

export default ProtectedPDFDownload;