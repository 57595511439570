import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from "../icons/logo-white.png"
import './EnterScreenComponent.css'

function EnterScreenComponent() {
    const navigate = useNavigate();

    return (
        <div className='enter-container'>
            <img src={logo} alt="logo" className='enter-logo'></img>
            <div className='enter-button'>
                {
                    <button className="enterButton" onClick={() => navigate("/intro")}>Enter</button>
                }
            </div>
        </div>
    );
}

export default EnterScreenComponent;