import React, { useState } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import './RightSphere.css';

function RightSphere() {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/contact'); // Redirect to the About page
    };

    return (
        <div className='right-outer-circle'>
            <div className='right-inner-circle' onClick={handleRedirect}>Free Assessment &#128070;</div>
        </div>
    );
}

export default RightSphere;