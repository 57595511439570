import React from 'react';

const PdfViewer = () => {
  const handleViewPdf = () => {
    // Redirect to the PDF file
    window.open('/RAAH Technologies_The Journey to Next Gen IAM 2024.pdf', '_blank'); // Open in a new tab
  };

  return (
    <div>
      <button onClick={handleViewPdf}>View PDF</button>
      <a href="/RAAH Technologies_The Journey to Next Gen IAM 2024.pdf" download>
        <button>Download PDF</button>
      </a>
    </div>
  );
};

export default PdfViewer;
