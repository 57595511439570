import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LeftSphere.css'
import { useAuth0 } from '@auth0/auth0-react';

function LeftSphere() {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/whitepaper/nextgeniam2025'); // Redirect to the About page
    };
    return (
        <div className='left-outer-circle'>
            <div className='left-inner-circle' onClick={handleRedirect}>Download IAM Next Gen Paper &#10515;</div>
        </div>
    );
}

export default LeftSphere;