import React, { useRef, useState, useEffect } from 'react';
import IdentityComponent from './components/IdentityComponent';
import ServicesComponent from './components/ServicesComponent';
import './App.css';
import AccomplishmentsComponent from './components/AccomplishmentsComponent';
import RecognitionComponent from './components/RecognitionComponent';
import TestimonialsComponent from './components/TestimonialsComponent';
import BlogComponentNew from './components/BlogComponentNew';
import ThinkingSphereComponent from './components/ThinkingSphereComponent';
import HeaderComponent1 from './components/headers/HeaderComponent1';
import HeaderComponent2 from './components/headers/HeaderComponent2';
import HeaderComponent3 from './components/headers/HeaderComponent3';
import HeaderComponent4 from './components/headers/HeaderComponent4';
import HeaderComponent5 from './components/headers/HeaderComponent5';
import HeaderComponent6 from './components/headers/HeaderComponent6';
import HeaderComponent7 from './components/headers/HeaderComponent7';
import HeaderComponent8 from './components/headers/HeaderComponent8';
import FooterComponent from './components/FooterComponent';
import KeysComponent from './components/KeysComponent';
import ContactComponent from './components/ContactComponent';
import HamburgerMenu from './components/overlays/HamburgerMenu';
import ScrollArrow from './components/overlays/ScrollArrow';
import Icon from './components/overlays/Icon';
import RightSphere from './components/overlays/RightSphere';
import LeftSphere from './components/overlays/LeftSphere';
import SkipButton from './components/overlays/SkipButton';

function App() {
  // const tagManagerArgs = {
  //   gtmId: 'G-3GMJJ7HRRW'
  // }
  // TagManager.initialize(tagManagerArgs)
  
  // window.dataLayer.push({
  //   event: 'pageview'
  // });
  const divIds = [
    'TitleComponentDiv',
    'H1',
    'IdentityComponentDiv',
    'H2',
    'ServicesComponentDiv',
    'H3',
    'AccomplishmentsComponentDiv',
    'H4',
    'TestimonialsComponentDiv',
    'H5',
    'RecognitionComponentDiv',
    'H6',
    'ContactComponent',
    'H7',
    'KeysComponent',
    'H8',
    'BlogComponentDiv',
    'Footer',
  ];

  const [windowScrollPercent, setWindowScrollPercent] = useState(0);
  const [divScrollPercents, setDivScrollPercents] = useState([0, 0, 0]);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const containerRef = useRef(null); // Reference to the scrollable container
  const skipButtonRef = useRef(null); // Reference to the SkipButton
  const contactRef = useRef(null); // Reference to the scrollable container

  // Function to calculate scroll percentage of the scrollable container
  const handleContainerScroll = () => {
    const container = containerRef.current;

    if (container) {
      const scrollTop = container.scrollTop;

      // Show the button when scrolled past the first section (which we assume is 100vh in height)
      if (scrollTop > window.innerHeight) {
        setIsButtonVisible(true);
      } else {
        setIsButtonVisible(false);
      }
    }

    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    const totalScroll = scrollHeight - clientHeight;
    const scrollPercentage = (scrollTop / totalScroll) * 100;
    setWindowScrollPercent(scrollPercentage);

    // console.log(`Container Scroll Percentage: ${scrollPercentage.toFixed(2)}%`);

    // Calculate individual div scroll percentages based on container scroll percentage
    const sections = document.querySelectorAll('.scroll-section');
    let startScrollPercent = 0;

    const newScrollPercents = Array.from(sections).map((section) => {
      const sectionHeight = section.clientHeight;
      const sectionScrollPercent = (sectionHeight / scrollHeight) * 100;

      let divScrollPercent = 0;
      if (
        windowScrollPercent >= startScrollPercent &&
        windowScrollPercent < startScrollPercent + sectionScrollPercent
      ) {
        // Calculate the scroll percentage within this section
        divScrollPercent =
          ((windowScrollPercent - startScrollPercent) / sectionScrollPercent) *
          100;
      } else if (
        windowScrollPercent >=
        startScrollPercent + sectionScrollPercent
      ) {
        divScrollPercent = 100; // Fully scrolled past the section
      }

      startScrollPercent += sectionScrollPercent;
      return divScrollPercent;
    });

    setDivScrollPercents(newScrollPercents);

    // Log each div scroll percentage
    newScrollPercents.forEach((percent, index) => {
      // console.log(`Div ${index + 1} Scroll Percentage: ${percent.toFixed(2)}%`);
    });
  };

  // Add event listener for container scroll
  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('scroll', handleContainerScroll);
    return () => {
      container.removeEventListener('scroll', handleContainerScroll);
    };
  }, [windowScrollPercent]);

  return (
    <div className="App container" ref={containerRef}>
      <Icon />
      <HamburgerMenu />
      <LeftSphere />
      <RightSphere />
      <ScrollArrow />
      {/* <SkipButton
        contactRef={contactRef}
        buttonClass={isButtonVisible ? 'visible' : 'hidden'} // Pass the class based on visibility
      /> */}
      <div
        id="TitleComponentDiv"
        className="scroll-section"
        style={{ height: '200vh', position: 'relative' }}
      >
        <ThinkingSphereComponent scrollPercentage={divScrollPercents[0]} />
      </div>
      <div id="H1" className="scroll-section" style={{ height: '100vh' }}>
        <HeaderComponent1 scrollPercentage={divScrollPercents[1]} />
      </div>
      <div
        id="IdentityComponentDiv"
        className="scroll-section"
        style={{ height: '300vh', position: 'relative' }}
      >
        <IdentityComponent scrollPercentage={divScrollPercents[2]} />
      </div>
      <div id="H2" className="scroll-section" style={{ height: '100vh' }}>
        <HeaderComponent2 scrollPercentage={divScrollPercents[3]} />
      </div>
      <div
        id="ServicesComponentDiv"
        className="scroll-section"
        style={{ height: '400vh', position: 'relative' }}
      >
        <ServicesComponent scrollPercentage={divScrollPercents[4]} />
      </div>
      <div id="H3" className="scroll-section" style={{ height: '100vh' }}>
        <HeaderComponent3 scrollPercentage={divScrollPercents[5]} />
      </div>
      <div
        id="AccomplishmentsComponentDiv"
        className="scroll-section"
        style={{ height: '300vh', position: 'relative' }}
      >
        <AccomplishmentsComponent scrollPercentage={divScrollPercents[6]} />
      </div>
      <div id="H4" className="scroll-section" style={{ height: '100vh' }}>
        <HeaderComponent4 scrollPercentage={divScrollPercents[7]} />
      </div>
      <div
        id="TestimonialsComponentDiv"
        className="scroll-section"
        style={{ height: '300vh', position: 'relative' }}
      >
        <TestimonialsComponent scrollPercentage={divScrollPercents[8]} />
      </div>
      <div id="H5" className="scroll-section" style={{ height: '100vh' }}>
        <HeaderComponent5 scrollPercentage={divScrollPercents[9]} />
      </div>
      <div
        id="RecognitionComponentDiv"
        className="scroll-section"
        style={{ height: '400vh', position: 'relative' }}
      >
        <RecognitionComponent scrollPercentage={divScrollPercents[10]} />
      </div>
      <div id="H6" className="scroll-section" style={{ height: '100vh' }}>
        <HeaderComponent6 scrollPercentage={divScrollPercents[11]} />
      </div>
      <div
        id="ContactComponent"
        className="scroll-section"
        style={{ height: '100vh' }}
        ref={contactRef}
      >
        <ContactComponent scrollPercentage={divScrollPercents[12]} />
      </div>
      <div id="H7" className="scroll-section" style={{ height: '100vh' }}>
        <HeaderComponent7 scrollPercentage={divScrollPercents[13]} />
      </div>
      <div
        id="KeysComponent"
        className="scroll-section"
        style={{ height: '300vh', position: 'relative' }}
      >
        <KeysComponent scrollPercentage={divScrollPercents[14]} />
      </div>
      {/* <div id="H8" className="scroll-section" style={{ height: '100vh' }}>
        <HeaderComponent8 scrollPercentage={divScrollPercents[15]} />
      </div>
      <div
        id="BlogComponentDiv"
        className="scroll-section"
        style={{ height: '500vh', position: 'relative' }}
      >
        <BlogComponentNew scrollPercentage={divScrollPercents[16]} />
      </div> */}
      <div id="Footer" className="scroll-section" style={{ height: '100vh' }}>
        <FooterComponent scrollPercentage={divScrollPercents[17]} />
      </div>
    </div>
  );
}

export default App;
